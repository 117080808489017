<template>
    <div id="Rentals" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Rentas
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="rentals_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Renta
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && rentals_data.length > 0">
                <li-table :data="rentals_data" 
                    :allow_headers="rentals_headers"
                    pagination_off>
                    <template v-slot:rent_discount="data">
                        <span>
                             $ {{ formatAmount(data.item.rent_discount, 2) }}
                        </span>
                    </template>
                    <template v-slot:start_date="data">
                        <span>
                            {{ new Date(data.item.start_date).toLocaleString() }}
                        </span>
                    </template>
                    <template v-slot:end_date="data">
                        <span>
                            {{ data.item.end_date ? new Date(data.item.end_date).toLocaleString() : 'Actualidad' }}
                        </span>
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectRent('edit', data.item)" 
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:details="data">
                        <button @click.stop="selectRent('details', data.item)" 
                            class="btn btn-outline-primary btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectRent('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Finalizar
                        </button>
                        <button v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Finalizado
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12 px-4" v-if="!fetch_error && !empty_search && rentals_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="rentals_modal" :max_width="1200" class="px-3">
                <div class="col-12">
                    <h3 v-if="!is_viewing">
                        Añadir Renta
                    </h3>
                    <h3 v-if="is_viewing">
                        Detalles
                    </h3>
                </div>

                <div class="col-12 col-md-6 p-2">
                    <li-select label="Conductor" v-if="!is_viewing"
                        :options="drivers_options"
                        v-model="id_driver" 
                        variant="secondary"
                        full/>
                    <span class="text-danger" v-if="errors.id_driver">
                        {{ errors.id_driver }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2">
                    <li-select @change="setVehicleData(id_vehicle)" v-if="!is_viewing"
                        label="Vehiculo"
                        :options="vehicles_options"
                        v-model="id_vehicle" 
                        variant="secondary"
                        full/>
                    <span class="text-danger" v-if="errors.id_vehicle">
                        {{ errors.id_vehicle }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Conductor" v-if="is_viewing"
                        :value="rent.driver_name"
                        type="text"
                        disabled
                        full>
                    </li-input>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Vehiculo" v-if="is_viewing"
                        :value="`${rent.model_name} ${rent.brand_name}`"
                        type="text"
                        disabled
                        full>
                    </li-input>
                </div>

                <div class="col-12 p-0 m-0">
                    <div class="row">
                        <div class="col-12">
                            <hr style="border: 1px solid #FFFFFF19;" />
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Marca"
                                v-model="brand_name"
                                type="text"
                                disabled
                                full>
                            </li-input>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Modelo"
                                v-model="model_name"
                                type="text"
                                disabled
                                full>
                            </li-input>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <div class="row p-0 m-0">
                                <div class="col-11 p-0">
                                    <li-input label="Color"
                                        v-model="color_name"
                                        type="text"
                                        disabled
                                        full>
                                    </li-input>
                                </div>
                                <div class="col-1 d-flex align-items-end p-0 m-0">
                                    <div class="mb-2 ml-2"
                                        style="width: 30px; height: 30px; border: 1px solid white; border-radius: 50%;"
                                        :style="`background-color: #${hex};`">
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Placas"
                                v-model="plate"
                                type="text"
                                disabled
                                full>
                            </li-input>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Número de Serie"
                                v-model="serial_number"
                                type="text"
                                disabled
                                full>
                            </li-input>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Coste de renta"
                                v-model="rental_rate"
                                type="text"
                                disabled
                                full>
                            </li-input>
                        </div>

                        <div class="col-12">
                            <hr style="border: 1px solid #FFFFFF19;" />
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Descuento sobre la renta"
                        v-model="rent_discount"
                        type="number"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.rent_discount">
                        {{ errors.rent_discount }}
                    </span>
                </div>

                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="createRent" v-if="!is_viewing"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearRental"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de terminar esta renta? 
                        <br>
                        Esta acción no puede revertirse
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="finishRent"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="edit_modal" :max_width="600" class="px-3">
                <div class="col-12 text-start">
                    <h4>
                        Editar descuento
                    </h4>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Descuento sobre la renta"
                        v-model="rent_discount"
                        type="number"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.rent_discount">
                        {{ errors.rent_discount }}
                    </span>
                </div>


                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="editRent"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="edit_modal = false; clearRental();"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                rentals_modal: false,
                delete_modal: false,
                edit_modal: false,
                rentals_headers: [
                    { key: 'driver_name', label: 'Conductor'},
                    { key: 'model_name', label: 'Modelo'},
                    { key: 'plate', label: 'Placas'},
                    { key: 'rent_discount', label:'Descuento'},
                    { key: 'start_date', label: 'Desde'},
                    { key: 'end_date', label: 'Hasta'},
                    { key: 'edit'},
                    { key: 'details', width: '10'},
                    { key: 'delete', width: '10'}
                ],
                rentals_data: [],
                drivers_options: [],
                vehicles_options: [],
                selected_id: null,
                id_driver: '0',
                id_vehicle: '0',
                rent_discount: null,
                is_viewing: false,
                errors: {
                    id_driver: null,
                    id_vehicle: null,
                    rent_discount: null
                },
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
                //vehicle info
                brand_name: null,
                model_name: null,
                color_name: null,
                hex: null,
                plate: null,
                serial_number: null,
                rental_rate: null
            }
        },
        computed: {
            ...mapGetters({
                rentals: 'rentals/getRentals',
                rent: 'rentals/getRent',
                vehicle: 'unit_administration/getUnit',
                avaliable_items: 'rentals/getAvaliableDriversVehicles',
                last_page: 'brands/getLastPage',
                total_pages: 'brands/getTotalPages'
            })
        },
        methods: {
            //helpers
            clearRental(){
                this.brand_name = null
                this.model_name = null
                this.color_name = null
                this.hex = null
                this.plate = null
                this.serial_number = null
                this.rental_rate = null
                this.id_driver = '0'
                this.id_vehicle = '0'
                this.rent_discount = null
                this.selected_id = null
                this.is_viewing = false

                for(let error in this.errors) {
                    this.errors[error] = null
                }

                this.rentals_modal = false
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('rentals/listRentals', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('rentals/listRentals', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('rentals/listRentals', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.rentals === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.rentals_data = this.rentals.map( rent => {
                    return {
                        ...rent,
                        edit: '-',
                        details: '-',
                        delete: '-'
                    }
                })
            },
            async setVehicleData(id){
                await this.$store.dispatch('unit_administration/viewVehicle', { id_vehicle: id});
                this.brand_name = this.vehicle.brand_name
                this.model_name = this.vehicle.model_name
                this.color_name = this.vehicle.color_name
                this.hex = this.vehicle.hex
                this.rental_rate = this.vehicle.rental_rate
                this.plate = this.vehicle.plate
                this.serial_number = this.vehicle.serial_number
            },
            async setOptions() {
                //opciones de coductores
                await this.$store.dispatch('rentals/avaliableDriversVehicles')
                this.drivers_options = this.avaliable_items.drivers.map( driver => {
                    return {
                        id: driver.id_driver,
                        label: driver.full_name
                    }
                })

                this.drivers_options = [
                    {id: 0, label: 'Seleccione'},
                    ...this.drivers_options
                ]

                //opciones de vehiculos
                this.vehicles_options = this.avaliable_items.vehicles.map( vehicle => {
                    return {
                        id: vehicle.id_vehicle,
                        label: `${vehicle.model_name} ${vehicle.plate || 'Sin Placas'}`
                    }
                })

                this.vehicles_options = [
                    {id: 0, label: 'Seleccione'},
                    ...this.vehicles_options
                ]
            },
            async selectRent(type, data) {
                switch(type) {
                    case 'details':
                        await this.$store.dispatch('unit_administration/viewVehicle', { id_vehicle: data.id_vehicle})
                        await this.$store.dispatch('rentals/viewRent', { id_rental: data.id_rental})
                        this.id_driver = this.rent.id_driver
                        this.id_vehicle = this.rent.id_vehicle
                        this.is_viewing = true
                        this.rentals_modal = true
                        this.brand_name = this.rent.brand_name
                        this.model_name = this.rent.model_name
                        this.color_name = this.rent.color_name
                        this.hex = this.rent.hex
                        this.plate = this.rent.plate
                        this.serial_number = this.vehicle.serial_number
                        this.rental_rate = this.vehicle.rental_rate
                        this.rent_discount = this.rent.rent_discount
                        return;
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos finalizados',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        await this.$store.dispatch('rentals/viewRent', { id_rental: data.id_rental})
                        this.selected_id = data.id_rental
                        this.rent_discount = this.rent.rent_discount
                        this.edit_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_rental
                        this.delete_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createRent() {

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(_.isEmpty(this.id_driver) || _.isNull(this.id_driver) || this.id_driver == '0') {
                    complete = false
                    this.errors['id_driver'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.id_vehicle) || _.isNull(this.id_vehicle) || this.id_vehicle == '0') {
                    complete = false
                    this.errors['id_vehicle'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.rent_discount) || _.isNull(this.rent_discount)) {
                    complete = false
                    this.errors['rent_discount'] = '*Campo obligatorio'
                }

                if(complete) {
                    const payload = {
                        id_driver: this.id_driver,
                        id_vehicle: this.id_vehicle,
                        rent_discount: this.rent_discount
                    }
                    
                    await this.$store.dispatch('rentals/addRent', payload)
                    this.filterElements()
                    this.clearRental()
                         
                }
            },
            async editRent() {

                const payload = {
                    id_rental: this.selected_id,
                    rent_discount: this.rent_discount
                }

                await this.$store.dispatch('rentals/editDiscount', payload)
                this.filterElements()
                this.clearRental()
                this.edit_modal = false
            },
            async finishRent() {
                await this.$store.dispatch('rentals/finishRent', { id_rental: this.selected_id, status: 'inactive'})
                this.filterElements()
                this.setOptions()
                this.delete_modal = false
                this.selected_id = null
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {

                //opciones drivers vehicles
                this.setOptions();

                //lista de Rentas
                await this.$store.dispatch('rentals/listRentals', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false})
                this.rentals_data = this.rentals.map( rent => {
                    return {
                        ...rent,
                        edit: '-',
                        details: '-',
                        delete: '-'
                    }
                })

                

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Rentals {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>